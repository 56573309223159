import { gql } from '@apollo/client';
import { CartPageFragment } from '@/talons/CartPage/cartPageFragments.gql';

export const GET_AVAILABLE_STORES_DATA = gql`
    query getAvailableStoresData {
        availableStores {
            category_url_suffix
            code
            default_display_currency_code
            id
            locale
            product_url_suffix
            store_code
            store_group_code
            store_group_name
            store_name
            store_sort_order
        }
    }
`;

export const getLanguageSwitcherContinents = gql`
    query getLanguageSwitcherContinents {
        languageSwitcherContinents {
            label
            countries {
                country_code
                label
                languages {
                    store_code
                    label
                    url
                    pwa_enabled
                    is_default
                }
            }
        }
    }
`;

export const handleStoreRedirect = gql`
    mutation switchStoreMutation($encodedUrl: String!, $sourceStoreCode: String!) {
        switchStore(input: { encoded_url: $encodedUrl, source_store_code: $sourceStoreCode }) {
            cart_id
            target_url
        }
    }
`;

export const CREATE_STORE_SWITCH_URL = gql`
    mutation createStoreSwitchUrlMutation($targetStoreCode: String!, $currentUrl: String!, $cartId: String) {
        createStoreSwitchUrl(input: { target_store_code: $targetStoreCode, current_url: $currentUrl, cart_id: $cartId })
    }
`;

export const createCartMutation = gql`
    mutation CreateCartAfterSignIn {
        cartId: createEmptyCart
    }
`;

export const getCartDetailsQuery = gql`
    query GetCartDetailsById($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            items {
                uid
                product {
                    uid
                    name
                    sku
                    small_image {
                        url
                        label
                    }
                    price {
                        regularPrice {
                            amount {
                                value
                            }
                        }
                    }
                }
                quantity
                ... on ConfigurableCartItem {
                    configurable_options {
                        configurable_product_option_uid
                        option_label
                        configurable_product_option_value_uid
                        value_label
                    }
                }
            }
            prices {
                grand_total {
                    value
                    currency
                }
            }
            ...CartPageFragment
        }
    }
    ${CartPageFragment}
`;

export default {
    createStoreSwitchUrl: CREATE_STORE_SWITCH_URL,
    getAvailableStoresData: GET_AVAILABLE_STORES_DATA,
};
