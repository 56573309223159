import { cookieManager } from '@vaimo-int/one-trust';
import { PrivacyGroupEnum } from '@vaimo-int/one-trust/dist/cookieManager/types';

/**
 * This is Client-side cookie handler only (client components)
 * Use "RequestContext.cookies" for SSR access to cookies during SSR in client components
 *
 * TODO add OT cookie validation
 */

interface ICookieOptions {
    expires?: number;
}
const DEFAULT_TTL = 60;

const isClient = globalThis.document && typeof globalThis.document.cookie === 'string';

export const getCookie = (name: string) => {
    if (!isClient) return;

    const cookie = (document.cookie.match(`(?:(?:^|.*; *)${name} *= *([^;]*).*$)|^.*$`) || [])[1];

    if (cookie) return decodeURIComponent(cookie).replace(/^"(.*)"$/, '$1');
};

export const isCookieSet = (name: string) => {
    if (!isClient) return;

    return !!document.cookie.split(';').some((el) => el.includes(name));
};

// Setting up Cookies ONLY in Client components AND on client side execution
export const setCookie = (name: string, value: string, groupId: PrivacyGroupEnum, options?: ICookieOptions) => {
    if (!isClient) return;

    const timeToLiveInMinutes = (options?.expires || DEFAULT_TTL) * 60 * 1000;
    cookieManager.setCookie({ groupId, name, timeToLiveInMinutes, value });
};

export const deleteCookie = (name: string) => {
    if (!isClient) return;

    if (getCookie(name) !== undefined) {
        globalThis.document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
    }
};
