'use client';

import React, { ReactElement, useCallback, useMemo } from 'react';
import loadable from '@loadable/component';
import { Form, Formik } from 'formik';
import { useTranslations } from 'next-intl';
import { useStoreSwitcherForm } from './hooks/useStoreSwitcherForm';

import Select from '@/components/Select';
import Button from '@/components/Button';
import Notification from '@/components/Notification';

import { FlagFallbackIcon } from '@/icons/fallback';
import mergeClasses from '@/utils/Object/shallowMerge';
import defaultClasses from './styles/storeSwitcherForm.module.scss';

interface IStoreSwitcherForm {
    classes?: {
        [className: string]: string;
    };
}

const StoreSwitcherForm = ({ classes: propClasses }: IStoreSwitcherForm): ReactElement => {
    const {
        availableCountries,
        availableLanguages,
        availableRegions,
        chosenCountry,
        chosenLanguage,
        chosenRegion,
        formRef,
        handleSelectStoreClick,
        handleSetChosenCountry,
        handleSetChosenLanguage,
        handleSetChosenRegion,
        isCurrentStoreCode,
        isSwitchButtonActive,
        shouldRenderError,
    } = useStoreSwitcherForm();

    const t = useTranslations('storeSwitcherFields');
    const classes = mergeClasses(defaultClasses, propClasses);

    const FlagIcon = loadable(() => import(`@/icons/flags/${chosenCountry}`), {
        fallback: <FlagFallbackIcon />,
    });

    const actionButtonLabel = useMemo(() => {
        if (isSwitchButtonActive) {
            return t.rich('switchCountryButtonText', {
                countryFlag: () => (chosenCountry ? <FlagIcon className={classes.storeSwitcherFieldsFlag} /> : null),
            });
        }

        if (isCurrentStoreCode) {
            return t('continueShopping');
        }

        return t('switchCountryButtonTextInactive');
    }, [chosenCountry, isCurrentStoreCode, isSwitchButtonActive]);

    const getActionButtonAriaLabel = useCallback(
        (isLocalized: boolean) => {
            const countryFlag = chosenLanguage || null;

            if (isSwitchButtonActive) {
                return isLocalized
                    ? (t.rich('switchCountryButtonText', { countryFlag: () => countryFlag }) as string)
                    : `Take me to the ${countryFlag} site`;
            }

            if (isCurrentStoreCode) {
                return isLocalized ? t('continueShopping') : 'Continue shopping';
            }

            return isLocalized ? t('switchCountryButtonTextInactive') : 'Select country';
        },
        [chosenLanguage, isCurrentStoreCode, isSwitchButtonActive],
    );

    const notification = useMemo(() => {
        if (shouldRenderError) {
            return <Notification variant="error">{t('noCountryData')}</Notification>;
        }

        return (
            <Notification>
                {isCurrentStoreCode ? t('switchCountryNoticeCurrent') : t('switchCountryNotice')}
            </Notification>
        );
    }, [isCurrentStoreCode, shouldRenderError, t]);

    const regionsSelectLabelLocalized = t('yourRegion');
    const countriesSelectLabelLocalized = t('yourCountry');
    const languagesSelectLabelLocalized = t('language');

    return (
        <Formik
            initialValues={{
                countries: chosenCountry || '',
                languages: chosenLanguage || '',
                regions: chosenRegion || '',
            }}
            innerRef={formRef}
            onSubmit={handleSelectStoreClick}
        >
            <Form className={defaultClasses.storeSwitcherForm}>
                {notification}
                <Select
                    field={'regions'}
                    label={regionsSelectLabelLocalized}
                    items={availableRegions}
                    isRequired
                    onChange={handleSetChosenRegion}
                    aria-label={regionsSelectLabelLocalized}
                />
                <Select
                    field={'countries'}
                    label={countriesSelectLabelLocalized}
                    items={availableCountries}
                    onChange={handleSetChosenCountry}
                    disabled={!chosenRegion}
                    isRequired
                    notice={chosenRegion && chosenCountry !== 'global' ? t('missingCountryText') : undefined}
                    aria-label={countriesSelectLabelLocalized}
                />
                <Select
                    field={'languages'}
                    label={languagesSelectLabelLocalized}
                    items={availableLanguages}
                    disabled={!chosenCountry}
                    isRequired
                    onChange={handleSetChosenLanguage}
                    aria-label={languagesSelectLabelLocalized}
                />
                <div className={classes.storeSwitcherFieldsButtonsWrapper}>
                    <Button
                        data-gtm-btn-label={getActionButtonAriaLabel(false)}
                        type="submit"
                        disabled={!isSwitchButtonActive && !isCurrentStoreCode}
                        role="button"
                        aria-label={getActionButtonAriaLabel(true)}
                    >
                        {actionButtonLabel}
                    </Button>
                </div>
            </Form>
        </Formik>
    );
};

StoreSwitcherForm.displayName = 'StoreSwitcherForm';

export default StoreSwitcherForm;
