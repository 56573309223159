import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useAppContext, useCartContext } from '@/lib/context';
import mergeOperations from '@/utils/Object/shallowMerge';
import DEFAULT_OPERATIONS from '@/components/StoreSwitcher/api/storeSwitcher.gql';
import { setCookie } from '@/utils/cookieManager';
import { useStoreSwitcherOptions } from './useStoreSwitcherOptions';
import { BrowserPersistence } from '@/utils';
import { STORE_SWITCHER_LOCATION, STORE_SWITCHER_VARIANT } from '@/constants/storeSwitcher';
import { IUseStoreSwitcherForm, IUseStoreSwitcherFormProps } from '@/components/StoreSwitcher/types';
import { STORE_SWITCHER } from '@/constants/storage';
import type { FormikProps } from 'formik';
import { cookieManager } from '@vaimo-int/one-trust';
import { GEOLOCATION_STORE_COOKIE } from '@/constants/cookies';
import { GEOLOCATION_STORE_COOKIE_TTL } from '@/components/StoreSwitcher/utils/const';
import { useStoreSwitcherContext } from '@/components/StoreSwitcher/context/storeSwitcherContext';

export const useStoreSwitcherForm = ({
    operations: propsOperations,
}: IUseStoreSwitcherFormProps = {}): IUseStoreSwitcherForm => {
    const { resolvedStoreGeolocationCode, resolvedStoreGeolocationCountry } = useStoreSwitcherContext();
    const storage = new BrowserPersistence();
    const formRef = useRef<FormikProps<{ countries: string; languages: string; regions: string }>>(null);
    const [
        { isStoreSwitcherOpen, storeConfig },
        {
            actions: { setIsStoreSwitcherOpen },
            closeDrawer,
        },
    ] = useAppContext();
    const [{ cartId }, { removeCart }] = useCartContext();

    // The fallback store is a store that's gonna be preserved as user's preference
    // when user opts out from using the geolocation modal
    // (cancels the modal, which means he'd rather stay where he currently is)
    const [fallbackStore, setFallbackStore] = useState('');
    const [chosenCountry, setChosenCountry] = useState(resolvedStoreGeolocationCountry);
    const [chosenLanguage, setChosenLanguage] = useState(resolvedStoreGeolocationCode);
    const [switcherVariant, setSwitcherVariant] = useState(STORE_SWITCHER_VARIANT.DEFAULT);

    const operations = mergeOperations(DEFAULT_OPERATIONS, propsOperations);
    const { createStoreSwitchUrl } = operations;

    const { storeCode, storeGroupName } = storeConfig;

    const [storeSwitchUrl] = useMutation(createStoreSwitchUrl);

    const {
        availableCountries,
        availableLanguages,
        availableRegions,
        chosenRegion,
        findDefaultLanguage,
        getRegionByCountryCode,
        setChosenRegion,
        shouldRenderError,
    } = useStoreSwitcherOptions({
        chosenCountry,
        storeGroupName,
        switcherVariant,
    });

    const setDefaultSwitcherVariant = useCallback(() => setSwitcherVariant(STORE_SWITCHER_VARIANT.DEFAULT), []);
    const setGeolocationSwitcherVariant = useCallback(() => setSwitcherVariant(STORE_SWITCHER_VARIANT.GEOLOCATION), []);

    const handleSetCookie = useCallback(
        (newStoreCode: string) => {
            setCookie(GEOLOCATION_STORE_COOKIE, newStoreCode || storeCode, cookieManager.PrivacyGroupEnum.FUNCTIONAL, {
                expires: GEOLOCATION_STORE_COOKIE_TTL,
            });
        },
        [storeCode],
    );
    const handleGeolocationPopupCancel = useCallback(() => {
        if (!isStoreSwitcherOpen) return;

        closeDrawer();
        setIsStoreSwitcherOpen({ isOpen: false });
        setDefaultSwitcherVariant();

        if (!fallbackStore) return;

        handleSetCookie(fallbackStore);
    }, [isStoreSwitcherOpen, storeCode, fallbackStore]);

    const handleGeolocationPopup = useCallback(
        (fallbackStore?: string, destinationStore?: string, shouldUseDestinationStoreCountry = false) => {
            if (!fallbackStore || !destinationStore) return;

            const [, destinationStoreCountry] = destinationStore.split('_');
            const newCountry = shouldUseDestinationStoreCountry
                ? destinationStoreCountry
                : resolvedStoreGeolocationCountry;

            setGeolocationSwitcherVariant();
            setIsStoreSwitcherOpen({ isOpen: true, location: STORE_SWITCHER_LOCATION.DEFAULT });
            setFallbackStore(fallbackStore);
            setChosenRegion(getRegionByCountryCode(newCountry));
            setChosenCountry(newCountry);
            setChosenLanguage(destinationStore);
        },
        [
            getRegionByCountryCode,
            resolvedStoreGeolocationCountry,
            setChosenRegion,
            setGeolocationSwitcherVariant,
            setIsStoreSwitcherOpen,
        ],
    );

    const handleSwitchStore = useCallback(
        async (selectedStoreCode: string) => {
            closeDrawer();
            setIsStoreSwitcherOpen({ isOpen: false });
            setDefaultSwitcherVariant();

            // Redirect to 'exception' countries that are not served by Magento BE and Global store
            if (selectedStoreCode.startsWith('http')) {
                return globalThis.location.assign(selectedStoreCode);
            }

            const response = await storeSwitchUrl({
                variables: {
                    cartId,
                    currentUrl: globalThis.location.href,
                    targetStoreCode: selectedStoreCode,
                },
            });

            handleSetCookie(selectedStoreCode);
            removeCart();
            globalThis.location.assign(response.data.createStoreSwitchUrl);
            storage.setItem(STORE_SWITCHER.SHOULD_SHOW_STORE_TOAST, 'true');
            storage.removeItem('store_view_currency');
        },
        [cartId, storeCode, storeSwitchUrl],
    );

    const onSubmit = useCallback(async () => {
        await handleSwitchStore(chosenLanguage);
    }, [chosenLanguage, handleSwitchStore]);

    const handleSetChosenRegion = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            const { value } = event.target;

            setChosenRegion(value);
            setChosenCountry('');
            setChosenLanguage('');
            formRef?.current?.setValues({ countries: '', languages: '', regions: value });
        },
        [setChosenRegion],
    );

    const handleSetChosenCountry = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            const { value } = event.target;
            const defaultLanguage = findDefaultLanguage({ chosenCountry: value, chosenRegion }) || '';

            setChosenCountry(value);
            setChosenLanguage(defaultLanguage);
            formRef?.current?.setValues({ ...formRef?.current?.values, countries: value, languages: defaultLanguage });
        },
        [chosenRegion, findDefaultLanguage],
    );

    const handleSetChosenLanguage = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;

        setChosenLanguage(value);
        formRef?.current?.setValues({ ...formRef?.current?.values, languages: value });
    }, []);

    useEffect(() => {
        formRef?.current?.setFieldValue('regions', chosenRegion);
    }, [chosenRegion]);

    const isCurrentStoreCode = storeCode === chosenLanguage;
    const isSwitchButtonActive = !!chosenLanguage && !!chosenCountry && !isCurrentStoreCode;

    const handleSelectStoreClick = useCallback(() => {
        if (isCurrentStoreCode) {
            return handleGeolocationPopupCancel();
        }

        onSubmit();
    }, [handleGeolocationPopupCancel, isCurrentStoreCode, onSubmit]);

    return {
        availableCountries,
        availableLanguages,
        availableRegions,
        chosenCountry,
        chosenLanguage,
        chosenRegion,
        formRef,
        handleGeolocationPopup,
        handleGeolocationPopupCancel,
        handleSelectStoreClick,
        handleSetChosenCountry,
        handleSetChosenLanguage,
        handleSetChosenRegion,
        handleSetCookie,
        isCurrentStoreCode,
        isSwitchButtonActive,
        setChosenCountry,
        setChosenLanguage,
        setChosenRegion,
        setDefaultSwitcherVariant,
        setGeolocationSwitcherVariant,
        shouldRenderError,
        switcherVariant,
    };
};
