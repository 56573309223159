import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { useAppContext } from '@/lib/context';
import { STORE_SWITCHER_VARIANT } from '@/constants/storeSwitcher';
import { getLanguageSwitcherContinents } from '@/components/StoreSwitcher/api/storeSwitcher.gql';
import {
    ILanguageSwitcherContinents,
    IUseStoreSwitcherOptions,
    IUseStoreSwitcherOptionsProps,
} from '@/components/StoreSwitcher/types';
import { useStoreSwitcherContext } from '@/components/StoreSwitcher/context/storeSwitcherContext';

export const useStoreSwitcherOptions = ({
    chosenCountry,
    storeGroupName,
    switcherVariant,
}: IUseStoreSwitcherOptionsProps): IUseStoreSwitcherOptions => {
    const { resolvedStoreGeolocationCountry } = useStoreSwitcherContext();
    const [{ isStoreSwitcherOpen }] = useAppContext();

    const { data: storeSwitcherOptionsData, loading } = useQuery<ILanguageSwitcherContinents>(
        getLanguageSwitcherContinents,
        {
            fetchPolicy: 'cache-and-network',
            skip: !isStoreSwitcherOpen,
        },
    );

    const storeSwitcherOptionsTree = useMemo(
        () => storeSwitcherOptionsData?.languageSwitcherContinents || [],
        [storeSwitcherOptionsData?.languageSwitcherContinents],
    );

    const findCurrentRegionByStoreName = ({ storeGroupName }: { storeGroupName: string }) =>
        storeSwitcherOptionsTree.find(({ countries }) => countries?.find(({ label }) => label === storeGroupName) || {})
            ?.label || '';

    const getRegionByCountryCode = (countryCode: string) =>
        storeSwitcherOptionsTree.find((collection) =>
            collection.countries.some(({ country_code }) => country_code === countryCode),
        )?.label || '';

    const [chosenRegion, setChosenRegion] = useState<string>('');

    useEffect(() => {
        const initialRegionValue =
            getRegionByCountryCode(
                (switcherVariant === STORE_SWITCHER_VARIANT.GEOLOCATION && resolvedStoreGeolocationCountry) ||
                    chosenCountry,
            ) || findCurrentRegionByStoreName({ storeGroupName });

        setChosenRegion(initialRegionValue || '');
    }, [storeSwitcherOptionsTree]);

    const findAvailableCountriesForRegion = ({ chosenRegion }: { chosenRegion: string }) =>
        storeSwitcherOptionsTree.find(({ label }) => label === chosenRegion)?.countries || [];

    const findAvailableLanguagesForCountry = ({
        chosenCountry,
        chosenRegion,
    }: {
        chosenCountry: string;
        chosenRegion: string;
    }) => {
        const availableCountries = findAvailableCountriesForRegion({ chosenRegion });

        return availableCountries?.find(({ country_code }) => country_code === chosenCountry)?.languages || [];
    };

    const findDefaultLanguage = ({ chosenCountry, chosenRegion }: { chosenCountry: string; chosenRegion: string }) => {
        const availableLanguages = findAvailableLanguagesForCountry({ chosenCountry, chosenRegion });

        return availableLanguages.find((language) => language.is_default)?.store_code || '';
    };

    const availableRegions = useMemo(
        () => storeSwitcherOptionsTree.map(({ label }) => ({ label, value: label }) || []),
        [storeSwitcherOptionsTree],
    );

    const availableCountries = useMemo(
        () =>
            findAvailableCountriesForRegion({ chosenRegion })?.map(({ country_code, label, languages }) => ({
                label,
                languages,
                value: country_code,
            })) || [],
        [chosenRegion, storeSwitcherOptionsTree],
    );

    const availableLanguages = useMemo(
        () =>
            findAvailableLanguagesForCountry({ chosenCountry, chosenRegion })?.map((language, index) => ({
                initialValue: index === 0 ? language.store_code || language.url : null,
                isDefaultLanguage: language.is_default,
                isPWAEnabled: language.pwa_enabled,
                label: language.label,
                value: language.store_code || language.url,
            })) || [],
        [chosenRegion, chosenCountry, storeSwitcherOptionsTree],
    );

    const shouldRenderError =
        !loading && !availableCountries?.length && !availableLanguages?.length && !availableRegions?.length;

    return {
        availableCountries,
        availableLanguages,
        availableRegions,
        chosenRegion,
        findDefaultLanguage,
        getRegionByCountryCode,
        setChosenRegion,
        shouldRenderError,
    };
};
